import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { HideElement } from '../Shared/Animations';

export const BackgroundOpacity = styled.div<{ showAlert: boolean }>`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  opacity: ${props => (props.showAlert ? 1 : 0)};
  width: 100%;
  height: 100%;
  overflow: auto;
  color: ${Colors.secondaryText};
  background-color: rgba(216, 216, 216, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in;
  ${props =>
    !props.showAlert &&
    css`
      animation: ${HideElement} 0.5s ease-in forwards;
    `}
`;
export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  border-radius: 14px;
  min-width: 450px;
  max-width: 500px;
  max-height: 90vh;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 100%;
    min-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`;
export const AlertHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  font-size: 16px;
  font-weight: 800;
`;
export const CloseAlert = styled.button<{ bgImage: string }>`
  width: 32px;
  height: 32px;
  background-color: transparent;
  background-image: url(${props => props.bgImage});
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
export const AlertDivider = styled.div`
  height: 1px;
  background-color: rgb(231, 231, 231);
  width: 100%;
`;
export const AlertBody = styled.div<{ minHeight?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 20px;
  width: calc(100% - 40px);
  max-height: 75vh;
  overflow-y: scroll;
  min-height: ${props => (props.minHeight ? `${props.minHeight}px` : 'auto')};
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    min-height: unset;
    height: 100%;
  }
`;
export const AlertFooter = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 20px;
  width: calc(100% - 40px);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#f1f1f1')};
  border-top: 2px solid #f1f1f1;
  @media (max-width: 768px) {
    justify-self: flex-end;
  }
`;

export const AlertDescription = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  min-height: 80px;
  width: 100%;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
