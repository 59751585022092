import AppStorage from './AppStorage';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import client from './client';
import { vars } from '../reactive';
import debounce from './debounce';

export const setLoginTokens = tokens => {
  AppStorage.set('tokens', tokens);
};

export const _logout = async () => {
  const userId = firebase?.auth?.()?.currentUser?.uid;
  try {
    if (firebase.messaging.isSupported()) {
      await firebase?.messaging().deleteToken();
    }
    await firebase?.firestore().collection('Users').doc(userId).set({ tokens: [] });
    await firebase?.auth?.().signOut();
  } catch (e) {
    // user signed in from elsewhere
    console.error('FIREBASE ERROR ON LOGOUT:', e);
  }
  AppStorage.remove('tokens');
  AppStorage.remove('apollo-cache-persist');
  client.clearStore();
  navigate('/login', { replace: true });
  vars.drawerBars([]);
  vars.bookingSelectedCategoryId(null);
  vars.petSelectedCategoryId(null);
  vars.calendarAvailabilityVisible(false);
  vars.calendarTagCategoryId(null);
  vars.branchSummaryCounts(null);
  vars.providerBranchId(null);
};
// this is used when logout is called multiple times (e.g multiple apis fail and logout is called multiple times)
export const logout = () => debounce(_logout, 500, 'LOGOUT');
