import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Colors from '../../../Colors';
export const SideTabContainer = styled.div<{ isModal?: boolean; backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 140px;
  min-width: 140px;
  padding: 10px;
  margin-top: 60px;
  font-size: 13px;
  overflow-y: auto;
  ${({ isModal }) =>
    isModal &&
    css`
      margin-top: 0;
      padding: 20px 16px;
      max-width: 180px;
      min-width: 180px;
      border-bottom-left-radius: 14px;
      background-color: #f4f4f4;
    `}
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  @media (max-width: 768px) {
    margin-top: 0;
    flex-direction: row;
    width: auto;
    max-width: 100%;
    gap: 20px;
    height: 64px;
    padding: 0 16px;
    overflow: unset;
    border-bottom: 1px solid #efefef;
  }
`;

export const Divider = styled.div<{ marginBottom?: string; marginTop?: string; backgroundColor?: string; hideOnDesktop?: boolean }>`
  display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'block')};
  min-height: 1px;
  max-height: 1px;
  height: 1px;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || `${Colors.divider}`};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  @media (max-width: 768px) {
    display: block;
    background-color: ${Colors.white};
  }
`;

export const SideTabLink = styled(Link)<{ isSelected: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  padding: 8px 6px;
  width: 100%;
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
  color: ${Colors.sideTabColor};
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${Colors.sideTabBackgroundColor};
      cursor: pointer;
      border-radius: 5px;
      color: ${Colors.black};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  &:hover {
    background-color: ${Colors.sideTabBackgroundColor};
    cursor: pointer;
    border-radius: 5px;
    color: ${Colors.black};
  }

  @media (max-width: 768px) {
    width: auto;
    color: #a6a6a6;
    font-size: 18px;
    font-weight: 800;
    padding: 0 0;
    &:hover {
      background-color: transparent;
      color: ${Colors.black};
    }

    ${({ isSelected }) =>
      isSelected &&
      css`
        background-color: transparent;
        color: ${Colors.black};
      `}
  }
`;

export const SideTabButton = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  padding: 8px 6px;
  width: 100%;
  font-weight: 700;
  font-size: 13px;
  text-decoration: none;
  color: ${Colors.sideTabColor};
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${Colors.sideTabBackgroundColor};
      cursor: pointer;
      border-radius: 5px;
      color: ${Colors.black};
    `}

  &:hover {
    background-color: ${Colors.sideTabBackgroundColor};
    cursor: pointer;
    border-radius: 5px;
    color: ${Colors.black};
  }
`;

export const NotificationDotIconContainer = styled.div`
  overflow: hidden;
  border-radius: 50%;
`;

export const TabName = styled.div``;
export const Count = styled.div`
  margin-left: auto;
  color: ${Colors.sideTabCountColor};
  font-weight: 500;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
  gap: 12px;
  justify-content: center;
  align-items: center;
`;
