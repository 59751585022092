import { SelectedChatsFilter } from '../components/Shared/Filters/config';
import { generateVars } from './utils';

const initialStates: {
  selectedChatsFilters: SelectedChatsFilter[];
  chatsHeaderActions: { id: string; action: JSX.Element }[];
  chatSelectedCategoryId: string | null;
  chatVariables: Record<string, any> | null;
  optimisticMessages: Record<string, any>[];
  activeChatViewSettings: {
    orderByIsRead: boolean;
  };
} = {
  selectedChatsFilters: [],
  chatsHeaderActions: [],
  chatSelectedCategoryId: null,
  chatVariables: null,
  optimisticMessages: [],
  activeChatViewSettings: {
    orderByIsRead: false
  }
};

export const persistedVarsStorageKeys = ['activeChatViewSettings'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
