import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import React from 'react';
import { Toaster } from 'sonner';
import { APIProvider } from '@vis.gl/react-google-maps';
import Alert, { AlertElement } from './src/components/Alert/Alert';

import { ApolloProvider } from '@apollo/client';
import { GlobalStyle } from './src/components/Shared/Shared';
import client from './src/utils/client';
import config from './src/utils/config';

const inWindow = typeof window !== 'undefined';

if (inWindow) {
  import('firebase/auth');
  import('firebase/app');
  import('firebase/firestore');
  import('firebase/messaging');
  import('firebase/storage');
  import('firebase/analytics');
  import('firebase/remote-config');
}

export const wrapRootElement = ({ element }) => {
  return (
    <RollbarProvider config={{ accessToken: config.ROLLBAR_KEY, environment: config.ROLLBAR_ENV }}>
      <RollbarErrorBoundary>
        <ApolloProvider client={client}>
          <GlobalStyle />
          <Toaster />
          <AlertElement
            ref={alertRef => {
              Alert.setContainer(alertRef);
            }}
          />

          <APIProvider apiKey={config.GOOGLE_MAPS_API_KEY}>{element}</APIProvider>
        </ApolloProvider>
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
};
