import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import Colors from '../../Colors';
import { getKeys } from '../../utils/helpers';
import { Dash, Rotate } from './Animations';

const isMac = typeof window !== `undefined` && window?.navigator.userAgent.includes('Mac');
export const isSafariBrowser = typeof window !== `undefined` && window?.navigator.userAgent.indexOf('Safari') > -1 && window?.navigator.userAgent.indexOf('Chrome') <= -1;

export const CalendarEventColors = { odd: 'rgb(218,235,223)', even: 'rgb(246,214,172)', '3n': 'rgb(228,245,254)', '4n': 'rgb(237,233,254)', '5n': 'rgb(185,216,228)' };

export const displayTime = (hours: number, min?: number) => {
  const minToDisplay = min !== 0 ? (min < 10 ? `0${min}` : min) : '00';

  if (hours === 0) {
    return `12:${minToDisplay} AM`;
  }
  if (hours >= 1 && hours < 12) {
    return `${hours}:${minToDisplay} AM`;
  }
  if (hours === 12) {
    return `${hours}:${minToDisplay} PM`;
  }
  if (hours >= 13 && hours <= 23) {
    return `${hours - 12}:${minToDisplay} PM`;
  }
};

export const HideNotificationAnim = keyframes`
  to {
    visibility: hidden;
    height: 0;
  }
`;

export const SlideDownActionsContainer = styled.div<{ gap: number }>`
  display: flex;
  gap: ${({ gap }) => gap}px;
`;

export const SlideDown = styled.div<{ show: boolean; loadingData?: boolean }>`
  transition: all ${({ show }) => (show ? '0.3s' : '0.1s')} ease-in-out;
  transform: ${({ show, loadingData }) => (show || loadingData ? 'translateY(0)' : 'translateY(-100%)')} translateX(-50%);
  opacity: ${({ show, loadingData }) => (show || loadingData ? 1 : 0)};
  position: fixed;
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
  color: white;
  top: 23px;
  left: 50%;
  width: 300px;
  z-index: 10;
  background-color: #090909;
  padding: 10px 20px;
  border-radius: 60px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Manrope',-apple-system,'Arial';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
    box-sizing: border-box;
  }

  html {
    overflow: hidden;
  }

  body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

  button{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    color: var(--text);
    font: inherit;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
  }

  mark {
    background-color: transparent;
    color: ${Colors.black};
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    visibility: none;
    display: none;
  }

    .rbc-calendar * {
    font-family: 'Manrope',-apple-system,'Arial';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
  }

  .rbc-event-label {
    display: none !important;
  }

  .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: none!important;
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border: none!important;
  }
  .rbc-header {
    border: none!important;
  }
  .rbc-time-header-content {
    justify-content: center;
  }

  /* ${getKeys(CalendarEventColors).map(
    item => `
    .rbc-row:nth-child(${item}) .rbc-row-segment .rbc-event {
      background-color: ${CalendarEventColors[item]}!important;
      padding: 10px 5px;
    }

    .rbc-event:nth-child(${item}), .rbc-day-slot:nth-child(${item}) .rbc-background-event:nth-child(${item}) {
      background-color: ${CalendarEventColors[item]}!important;
    }
  `
  )} */

  ${
    !isMac &&
    css`
      *::-webkit-scrollbar {
        background-color: none;
        width: 6px;
        height: 6px;
      }
      *::-webkit-scrollbar-track {
        background-color: none;
      }
      *::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
      }
      *::-webkit-scrollbar-button {
        display: none;
      }

      @media (max-width: 768px) {
        *::-webkit-scrollbar {
          width: 3px;
        }
      }
    `
  }
  p {
    margin: 0;
    padding: 0;
  }
  input:disabled {
    border: none !important;
    background: #f4f4f4;
  }
  [class^='notification__item'], [class*=' notification__item'] {
    border: none;
  }
  .notification__timer-filler {
    opacity: 0;
  }
.swipeable-list{
  overflow:unset !important;
}
.swipeable-list-item{
  overflow:unset !important;
}

/* 
  .notification :has(.notification__action) {
    transition:none !important;
    width: auto !important;
    height: auto !important;
  }

  .notification__action.notification__item--info {
    width: 459px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    z-index: 100;
    border-left:none;
    color: ${Colors.white};
    padding: 8px;
    font-weight: 800;
    text-align: center;
    background-color: ${Colors.orangeMedium};
    box-shadow: 0 0 30px 0 rgba(209, 56, 0, 0.33);
    animation:none;
    .notification__content{
      padding:0;
      background-color: ${Colors.orangeMedium};
      z-index: 0;

      .notification__message {
        font-size: 16px;
        font-family: 'Manrope';
      }

      .notification__close {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border: none;
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        margin-right: 4px;
        outline: none;
        cursor: pointer;
        margin-left: auto;
        background-color: ${Colors.orangeMedium};
        background-image: url(${'../../images/closeTransparent.png'});
        :after{
          display:none;
        }
      }
    }
  }

 */


  .wrapper-class {
    border: 1px solid #aeaeae;
    border-radius: 4px;
    overflow: hidden;
    max-width: 400px;
  }

  .editor-class {
    padding: 1rem;
    height: 400px;
    overflow: scroll;
  }

  .toolbar-class {
  }
  .rdw-option-wrapper {
    border: none;
    padding: 1px;
    min-width: 25px;
    height: 20px;
    border-radius: 4px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
    &:hover{
      box-shadow: none;
      background: #e7e7e7;
    }
  }
`;
export const TabsHeaderContainer = styled.div<{
  hideTabs?: boolean;
  hasActions?: boolean;
  hasActionsPadding?: boolean;
  noPadding?: boolean;
  padding?: string;
  mobilePadding?: string;
  mobileMinHeight?: string;
  width?: string;
  boxShadow?: boolean;
  overflowX?: string;
  MobileBoxShadow?: boolean;
}>`
  display: flex;
  padding: 24px 32px;
  box-shadow: ${props => (props.boxShadow ? 'rgba(0, 0, 0, 0.05) 0px 10px 16px 0px' : 'none')};
  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `}
  padding-bottom: 0;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  /* align-items: flex-start; */
  justify-content: space-between;
  z-index: 50;
  overflow-x: ${props => (props.overflowX ? props.overflowX : 'none')};
  /* overflow-y: hidden; */
  /* max-height: 60px;
  min-height: 60px; */
  max-width: calc(100vw - 90px);
  width: ${props => props.width || 'auto'};
  @media (max-width: 1200px) {
    /* min-height: 60px; */
    ::-webkit-scrollbar {
      height: 3px;
    }
  }

  ${({ hasActionsPadding }) =>
    hasActionsPadding &&
    css`
      padding: 0 32px;
    `}

  @media (max-width: 768px) {
    align-items: center;
    z-index: 1;
    max-width: 100%;
    padding: ${props => (props.mobilePadding ? props.mobilePadding : 0)};
    min-height: ${props => (props.mobileMinHeight ? props.mobileMinHeight : '56px')};
    box-shadow: ${props => (props.MobileBoxShadow ? '0px 10px 16px 0px rgba(0, 0, 0, 0.05)' : 'none')};
    z-index: ${props => (props.MobileBoxShadow ? 1 : 0)};
  }
  ${({ hasActionsPadding }) =>
    hasActionsPadding &&
    css`
      padding: 0;
    `}

  ${({ hideTabs, hasActions }) =>
    hideTabs &&
    !hasActions &&
    css`
      display: none;
    `}
`;
export const TabsActionsWrapper = styled.div<{ backgroundColor?: string; hasActions?: boolean; actionsWidth?: number; actionsPadding?: string }>`
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  min-height: 80px;
  --actionsWidth: ${({ actionsWidth }) => actionsWidth}px;
  overflow-x: scroll;

  &:last-child {
    gap: 10px;
  }

  &:first-child {
    gap: 44px;
  }

  @media (max-width: 1200px) {
    gap: 0;
  }

  @media (min-width: 768px) {
    ${({ hasActions }) =>
      hasActions &&
      css`
        &:first-child {
          width: calc(100% - var(--actionsWidth));
          max-width: calc(100% - var(--actionsWidth));
        }

        &:last-child {
          width: var(--actionsWidth);
          max-width: var(--actionsWidth);
          justify-content: flex-end;
        }
      `}
  }

  overflow-x: scroll;

  @media (max-width: 768px) {
    justify-content: space-between;
    align-items: center;
    min-height: unset;
    padding-bottom: 0;
    flex: unset;
    min-width: unset;
    &:first-child {
      gap: 24px;
    }
    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${backgroundColor};
      `}

    ::-webkit-scrollbar {
      height: 3px;
    }
  }

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: backgroundColor;
    `}

  ${({ actionsPadding }) =>
    actionsPadding &&
    css`
      padding: ${actionsPadding};

      @media (max-width: 768px) {
        padding: ${actionsPadding};
      }
    `}

    padding-bottom: 0;
`;
export const TabTitle = styled.button<{ selected: boolean; disabled?: boolean; higherRadius?: boolean; mobileFontSize?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 800;
  color: ${props => (props.selected ? Colors.primary : Colors.secondaryGrey)};
  padding-bottom: 16px;
  flex: 0 0 auto;
  ${props =>
    props.disabled
      ? css`
          color: ${Colors.placeholder};
        `
      : css`
          &:hover {
            color: ${Colors.primary};
            cursor: pointer;
          }
        `}
  &:after {
    content: '';
    border: 1px solid transparent;
    width: 14px;
    display: block;
    margin-left: 9px;
    margin-right: 9px;
    border-radius: 10px;
    margin-top: 8px;
  }

  ${props =>
    props.selected &&
    css`
      &:after {
        content: '';
        border: 1px solid ${Colors.primary};
        width: 14px;
        display: block;
        margin-left: 9px;
        margin-right: 9px;
        border-radius: 10px;
        margin-top: 8px;
      }
    `}
  &:focus {
    outline: none;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1200px) {
    margin: 0 16px;
    font-size: 14px;
    background: ${props => (props.selected ? Colors.primary : '#a5a5a5')};
    color: ${Colors.white};
    border-radius: 16px;
    ${props =>
      props.higherRadius &&
      css`
        border-radius: 17px;
      `}
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 12px;
    min-width: 120px;
    &:after {
      content: none;
    }
    &:hover {
      color: ${Colors.white};
      background: ${Colors.primary};
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    font-size: ${props => (props.mobileFontSize ? props.mobileFontSize : 18)}px;
    font-weight: 800;
    min-width: unset;

    background: ${props => (props.selected ? 'none' : 'transparent')};
    color: ${props => (props.selected ? Colors.black : '#A6A6A6')};
    border-radius: 12px;
    ${props =>
      props.higherRadius &&
      css`
        border-radius: 18px;
      `}

    display: flex;
    justify-content: flex-start;
    align-items: start;
    text-align: left;

    &:after {
      content: none;
    }
    &:hover {
      color: ${Colors.black};
      background: none;
      cursor: pointer;
    }
  }
`;

export const TabTitleItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RedDot = styled.div<{ color?: string; mobileLeft?: string; mobileBottom?: string; mobileZIndex?: boolean; mobileTop?: string; top?: string; left?: string }>`
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  position: absolute;
  top: ${props => props.top || '16px'};
  left: ${props => props.left || '40px'};
  z-index: ${props => (props.mobileZIndex ? 100 : 0)};
  border-radius: 50%;
  background-color: ${props => props.color || Colors.alert};
  box-sizing: border-box;
  border: 2px solid ${Colors.white};
  /* margin-right: 8px; */
  @media (max-width: 768px) {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    max-width: 12px;
    max-height: 12px;
    left: ${props => props.mobileLeft || '30px'};
    bottom: ${props => props.mobileBottom || '3px'};
    top: ${props => props.mobileTop || 'auto'};
  }
`;

export const TabNotification = styled.span<{ selected?: boolean; width?: string; height?: string; mobileWidth?: string; mobileHeight?: string }>`
  font-size: 14px;
  font-weight: 800;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.greyOutTitle};
  color: ${Colors.grey};
  margin-left: 8px;
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '20px'};
  /* padding: 2px; */
  font-size: 12px;
  ${props =>
    props.selected &&
    css`
      background: ${Colors.primary};
      color: ${Colors.white};
    `}

  @media (max-width: 1200px) {
    background: ${Colors.white};
    color: ${Colors.primary};
    margin-left: 8px;
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  @media (max-width: 768px) {
    background: #d72929;
    color: ${Colors.white};
    margin-left: 8px;
    width: ${props => props.mobileWidth || '25px'};
    height: ${props => props.mobileHeight || '25px'};
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
  }
`;

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  width: 100%;
`;

export const FlexRow = styled.div<{
  reverse?: boolean;
  gap?: number;
  justify?: string;
  width?: string;
  flex1?: boolean;
  margin?: string;
  maxWidth?: string;
  padding?: string;
  hideOnDesktop?: boolean;
}>`
  flex-direction: row;
  padding: ${props => props.padding || '0'};
  display: flex;
  display: ${props => props.hideOnDesktop && 'none'};
  margin: ${props => props.margin || '0'};
  max-width: ${props => props.maxWidth || '100%'};
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.flex1 &&
    css`
      flex: 1;
    `}

  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${props =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `}

  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    @media (max-width: 768px) {
    display: flex;
  }
`;
export const FlexCenterCenter = styled.div<{ height?: number; width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `};
`;
export const FlexColumnCenterCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const FormDivider = styled.div`
  height: 1px;
  background-color: ${Colors.inputBorder};
  margin: 20px 0px;
`;
export const Container = styled.div<{ width?: number; isFlex?: boolean }>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  ${props =>
    props.isFlex &&
    css`
      display: flex;
      flex-direction: column;
      gap: 20px;
    `}

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ContainerFluid = styled.div`
  padding: 32px;
`;
export const Row = styled.div`
  display: table;
  width: 100%;
`;
type ColPropsTypes = {
  size?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};
export const Col = styled.div<ColPropsTypes>`
  float: left;
  width: auto;
  margin-right: 24px;
  ${props =>
    props.size &&
    css`
      width: calc((100% / 12) * ${props.size > 12 ? 12 : props.size} - 24px);
    `};
  ${props =>
    props.xs &&
    css`
      @media (max-width: 480px) {
        width: calc((100% / 12) * ${props.xs > 12 ? 12 : props.xs} - 24px);
      }
    `};
  ${props =>
    props.md &&
    css`
      @media (max-width: 768px) {
        width: calc((100% / 12) * ${props.md > 12 ? 12 : props.md} - 24px);
      }
    `};
  ${props =>
    props.lg &&
    css`
      @media (max-width: 1024px) {
        width: calc((100% / 12) * ${props.lg > 12 ? 12 : props.lg} - 24px);
      }
    `};
`;
export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin: -25px 0 0 -25px;
`;
export const StyledSpinner = styled.svg<{ size?: number; color?: string }>`
  animation: ${Rotate} 2s linear infinite;
  width: ${props => props.size || 100}px;
  height: ${props => props.size || 100}px;
  & .path {
    stroke: ${props => props.color || Colors.black};
    stroke-linecap: round;
    animation: ${Dash} 1.5s ease-in-out infinite;
  }
`;

export const LoadingOverlayContainer = styled.div`
  height: 105%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 100;
  position: absolute;
`;

export const CenteredLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const VerticallyCenteredLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const FlexBreak = styled.span`
  flex-basis: 100%;
`;
export const Flex1 = styled.div`
  display: flex;
  flex: 1;
`;
export const EmptyListContainer = styled.div<{ containerWidth?: string; containerHeight?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.containerHeight ? props.containerHeight : 'calc(100vh - 129px)')};
  width: ${props => (props.containerWidth ? props.containerWidth : '100%')};
  font-size: 12px;
  font-weight: 600;
  color: grey;
`;

export const ElasticFont = ({ text, size, compact }: { text: string; size: number; compact?: boolean }) => {
  let vSize = 110,
    textToRender = text;
  if (size === 16) {
    const maxLen = compact ? 12 : 14;
    vSize = Math.max(9.37 * maxLen, compact ? 60 : 80);
    if (text.length > maxLen) {
      textToRender = text.slice(0, maxLen) + '..';
    }
  } else if (size === 14) {
    const maxLen = compact ? 12 : 14;
    vSize = Math.max(7.08 * maxLen, 90);
    if (text.length > maxLen) {
      textToRender = text.slice(0, maxLen) + '..';
    }
  }
  return (
    <svg viewBox={`0 0 ${vSize} 20`}>
      <text x="0" y="15" fill={Colors.black}>
        {textToRender}
      </text>
    </svg>
  );
};

export const ColorCircle = styled.div<{
  width?: number;
  borderRadius?: number;
}>`
  height: ${props => props.width || 20}px;
  width: ${props => props.width || 20}px;
  border-radius: ${props => props.borderRadius || 10}px;
  background-color: ${props => props.color};
  margin-right: 8px;
`;

export const PatternCircle = styled.img<{
  width?: number;
  borderRadius?: number;
}>`
  height: ${props => props.width || 20}px;
  width: ${props => props.width || 20}px;
  border-radius: ${props => props.borderRadius || 10}px;
  margin-right: 8px;
`;

export const ExpandableOverflow = ({ children }: { children: React.ReactNode }) => {
  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(true);
  const showMoreText = showMore ? 'Show less' : 'Show more';

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(containerRef.current.scrollHeight > containerRef.current.clientHeight);
    }
  }, [containerRef.current, children]);

  return (
    <div
      ref={containerRef}
      style={{
        height: showMore ? 'auto' : '30px',
        position: 'relative',
        overflow: 'hidden',
        transition: 'height 0.5s ease-in-out'
      }}
    >
      {children}
      {isOverflowing && (
        <div onClick={toggleShowMore} style={{ cursor: 'pointer', color: Colors.primary, position: 'absolute', bottom: 0, right: 0 }}>
          {showMoreText}
        </div>
      )}
    </div>
  );
};

export const ErrorNotificationContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: ${Colors.primary};
  width: 600px;
  border-radius: 10px;
  color: ${Colors.white};
  padding: 20px 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ToggleSwitch = ({ checked, onChange }: { checked?: boolean; onChange: (checked: boolean) => void }) => {
  return (
    <div
      style={{
        width: 30,
        height: 16,
        borderRadius: 10,
        backgroundColor: Colors.placeholder,
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.3s ease-in-out',
        padding: 2,
        cursor: 'pointer',
        position: 'relative'
      }}
      onClick={() => onChange(!checked)}
    >
      <div
        style={{
          width: 18,
          height: 18,
          position: 'absolute',
          left: checked ? 18 : 0,
          transition: 'all 0.2s ease-in-out',
          borderRadius: 10,
          backgroundColor: Colors.black
        }}
      />
    </div>
  );
};

export const ViewWrapper = styled.div<{ paddingLeft?: number }>`
  display: flex;
  padding: 12px 12px 12px ${props => props.paddingLeft || 0}px;
  height: 100%;
  background-color: ${Colors.backgroundGrey};
  overflow-y: hidden;
  box-sizing: border-box;
  @media (max-width: 768px) {
    display: unset;
  }
`;

export const MainContainer = styled.div`
  background-color: ${Colors.white};
  border: 1px solid #e9e9e9;
  width: 1100px;
  height: 100%;
  flex: 1;
  border-radius: 16px;
  overflow-y: unset;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: auto;
    max-width: 768px;
    border: 0;
    border-radius: 0px;
    overflow-x: hidden;
  }
  position: relative;
  .gm-style-iw {
    border-radius: 30px !important;
  }
`;

export const ModalWrapper = styled.div<{ compact?: boolean; isMini?: boolean }>`
  display: flex;
  flex: ${props => (props.isMini ? 1 : '1 0 0')};
  /* background-color: ${Colors.backgroundGrey}; */
  box-sizing: border-box;
  /* height: 70vh; */
  /* max-height: 650px; */
  align-self: stretch;
  overflow-y: hidden;
  ${props =>
    !props.compact &&
    css`
      height: 100%;
      width: 100%;
    `}
`;

export const ModalContent = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: ${Colors.white};
  padding: ${props => (props.noPadding ? 0 : 20)}px;
`;

export const HideOnMobile = styled.div<{ disable?: boolean }>`
  @media (max-width: 768px) {
    ${props =>
      props.disable
        ? ''
        : css`
            display: none;
          `}
  }
`;

export const HideOnDesktop = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
