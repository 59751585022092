import { DRAWER_IDS } from '../components/DrawerBar/types';
import { BranchSummaryFormValues } from '../views/Store/BranchSummary/types';
import { generateVars } from './utils';

export type DrawerBar = {
  drawerId: DRAWER_IDS;
  recordData?: Record<string, any> | string;
  isPinned?: boolean;
  isExtended?: boolean;
  isFloating?: boolean;
  drawerWidths?: { normal: number; small: number };
  otherData?: Record<string, any>;
  style?: string | null;
  displayRelatedPets?: boolean;
};
const initialStates: {
  drawerBars: DrawerBar[];
  branchSummaryCounts: { counts?: BranchSummaryFormValues['selectedCounts']; isExpanded?: boolean; modes?: { index: number; mode: 'table' | 'chart' }[] } | null;
  billingSelectedCycle: { billing_date_from: string; billing_date_to: string } | null;
  busUserShiftSelectedCycle: { cycle_from: string; cycle_to: string } | null;
  providerBranchId: string | null;
  triggerAccountSwitchAnimation: boolean;
  preventExit: boolean;
} = {
  drawerBars: [],
  branchSummaryCounts: null,
  billingSelectedCycle: null,
  busUserShiftSelectedCycle: null,
  providerBranchId: null,
  triggerAccountSwitchAnimation: false,
  preventExit: false
};

export const persistedVarsStorageKeys = ['branchSummaryCounts', 'providerBranchId'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
